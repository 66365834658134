
const About = () => { 

    return (
        <div className={"container"}>
            Check back soon for some updates.
        </div>
    )
}


export default About