import styled from 'styled-components'

export const Badges = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0 1rem 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: start;
  width: 100%;
  
  li { 
    list-style-type: none;
    padding: 6px 10px;
    font-size: .8rem;
    font-weight: 500;
    margin: .2rem;
  }

`