import Button from '../parts/Button'

const ConnectWithMe = () => { 
    const username = "akrausedesign"
    const connections = [
        // {
        //   text: "Linkedin",
        //   icon: "linkedin-in",
        //   iconType: "brand",
        //   url: "https://www.linkedin.com/in/"
        // },
        {
        text: "Dribbble",
        icon: "dribbble",
        iconType: true,
        url: "https://www.dribbble.com/"
        },
        // {
        // text: "Twitter",
        // icon: "twitter",
        // iconType: true,
        // url: "https://www.twitter.com/"
        // },
        {
        text: "Github",
        icon: "github",
        iconType: true,
        url: "https://www.github.com/"
        }
    ]
    
    

    return (
        <section id={"connect"}>
        <div className={"container"}>
          <h2>Connect With Me</h2>
          <div className={"connection-links"}>
            {connections.map((connect, index) => (
              <Button
                key={index}
                text={connect.text}
                icon={connect.icon}
                brandIcon={connect.iconType}
                url={connect.url + username}
              />
            ))}
          </div>
        </div>
      </section>

    )
}

export default ConnectWithMe