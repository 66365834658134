import AboveTheFold from '../modules/AboveTheFold'
import Expertise from "../modules/Expertise"
import ConnectWithMe from '../modules/Social'
import AboutMe from "../modules/AboutMe"
import Project from "../modules/Project"
import Contact from "../modules/Contact"

// images
import face from "../assets/images/andrew-min.jpg"

const Home = () => { 
    return ( 
        <>
            <AboveTheFold />
            <Expertise />
            <ConnectWithMe />
            <AboutMe 
                profilePic={face}
                name="Andrew Krause"
                description="Originally from Kansas City, I studied at the Metropolitan Community College, and obtained an associates in Computer Science. Over the years I’ve learned many languages, frameworks, ideas, and have had the opportunity to work with startups, IOT firms, banks, and marketing agencies."            
            />
            <Project />
            <Contact
                subtitle="What do you need?"
                description="I’d love to meet with you and discuss your project. I’m comfortable working with innovative design, corporate branding structures, and a wide range of software engineering needs."
                topBorder={true}            
            />
        </>
    )
}


export default Home