import {IconLink} from "../parts/Icon"
import Copyright from "../modules/Copyright"

type FooterProps = {
    footerPic: string;
    picAlt: string;
}

const Footer = ({footerPic, picAlt}: FooterProps) => {
    const social = [
        // {
        //   text: "Linkedin",
        //   icon: "linkedin-in",
        //   iconType: true,
        //   url: "https://www.linkedin.com/in/andrew-krause-54092292/"
        // },
        {
            text: "Dribbble",
            icon: "dribbble",
            iconType: true,
            url: "https://www.dribbble.com/akrausedesign"
        },
        // {
        //   text: "Twitter",
        //   icon: "twitter",
        //   iconType: true,
        //   url: "https://www.twitter.com/akrausedesign"
        // },
        {
            text: "Stackoverflow",
            icon: "stack-overflow",
            iconType: true,
            url: "https://www.stackoverflow.com/users/5994673/andrew"
        },
        {
            text: "Github",
            icon: "github",
            iconType: true,
            url: "https://www.github.com/akrausedesign"
        }
    ]

    return (
        <footer>
            <div className="container">
                <div className={"footer-container"}>
                    {footerPic ? (
                        <img src={footerPic} alt={picAlt}/>
                    ) : null}
                    <div className={"icons-container"}>
                        {social.map((connect, index) => (
                            <IconLink
                                key={index}
                                brandIcon={connect.iconType}
                                icon={connect.icon}
                                url={connect.url}
                                text={connect.text}
                            />
                        ))}
                    </div>
                </div>
                <div style={{textAlign: "center"}}>
                    <Copyright />
                </div>
            </div>
        </footer>

    )
}

export default Footer