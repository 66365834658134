import Button from "../parts/Button"

type AboutMeProps = {
    profilePic: string;
    name: string;
    description: string;
}

const AboutMe = ({profilePic, name, description}: AboutMeProps) => { 

    return (
        <section id="about">
        <div className="container">
          <h2>About Me</h2>
          {profilePic ? <img src={profilePic} alt={name} /> : null}
          <h1>{name}</h1>
          <p>{description}</p>
          <Button text="Contact Me" url="#contact" />
        </div>
      </section>
    )
}

export default AboutMe