import Button from "../parts/Button"
import {FlexReset} from "../parts/styled/Displays";
import ProjectMeta from "../parts/ProjectMeta";

export interface ILabels {
  label: string
}

export interface ILanguages {
  label: string
}

export interface IFrameworks {
  label: string
}


export type TProject = {
  name: string
  description: string
  url: string
  labels?: ILabels[]
  languages?: ILanguages[]
  frameworks?: IFrameworks[]
  external: boolean
}


const Project = () => { 
    const projects: TProject[] = [
      {
        name: "Mercury Broadband",
        description:
          "Designed and created in Adobe XD, and currently maintained in Figma, this project was built with Wordpress and included a custom PHP plugin connecting to operational systems software that manages the entire company, which I have proudly designed and maintained as well. Mercury Broadband is an Internet Service Provider that services customers currently in Kansas and Indiana, with future plans to expand into 5 more states, and spend half a billion dollars into building a fiber network in Rural America.",
        url: "https://www.mercurybroadband.com",
        languages: [
          { label: "PHP", },
          { label: "MySQL", }, 
          { label: "HTML5", },
          { label: "SCSS", },
        ],
        external: true
      },
      {
        name: "Mercury Locations Campaign",
        description:
          "Mercury Broadband launches campaigns in every single city or town that they expand into. With that rose the challenge of designing a template that simulatneously connects the audience to the brand across 131 different locations and their communities.",
        url: "https://mercurybroadband.com/locations/topeka-ks",
        labels:  [
          { label: "Advanced Custom Fields" },
          { label: "Custom Post Types UI" }
        ],
        languages: [
          { label: "PHP", },
          { label: "MySQL", }, 
          { label: "HTML5", },
          { label: "JavaScript", },
          { label: "SCSS", },
        ],
        external: true
      },
      {
        name: "CERES OSS",
        description:
          "CERES is the bread & butter of Mercury Broadband. Initially built with the Skote dashboard design template and laravel, CERES is now a custom designed operational systems dashboard that helps employees in accounting, sales, customer support, field operations, network, and business departments organize their day to day operations.",
        url: "/projects/CERES",
        labels:  [
          { label: "Accuweather API", },
          { label: "Google Maps API", },
          { label: "Google Maps Geolocation API", },
          { label: "Google Maps Address Validation API", },
          { label: "Twilio API", },
          { label: "Microsoft Graph", },
          { label: "Mailgun API", },
        ],
        languages: [
          { label: "PHP", },
          { label: "MYSQL", }, 
          { label: "Python", },
          { label: "JavaScript", },
          { label: "NodeJS", },
        ],
        frameworks: [
          { label: "ReactJS", },
          { label: "Laravel", },
          { label: "ExpressJS", },
          { label: "Flask", },
          { label: "Django", },
        ],
        external: false
      },
      {
        name: "Renasant Nation",
        description:
          "Created using Adobe XD, Illustrator, Photoshop, After Effects, WordPress, and AngularJS, Renasant Nation is the media platform for Renasant Bank. They host a wide range of blog posts, mostly custom designs, that have audiences of over 10,000 people per week. Renasant Nation facilitates a space to hold sponsored YouTube videos and other customized series, as well as different campaigns.",
        url: "https://www.renasantnation.com",
        external: true
      },
      {
        name: "Renasant Mortgage",
        description:
          "Created using WordPress and Salesforce. The decisioning tool allows Mortgage customers to create a personalized data point for Advisors to reach out and provide them the most accurate quotes as possible, saving them time waiting in line at a physical branch location. We used Salesforce and Pardot to track user’s engagement in emails and to see what form elements they were stopping at or filling out incorrectly, as well as to collect general data for different types of personalized surveys that could be sent via email.",
        url: "https://www.renasantbank.com/mortgage",
        external: true
      },
      {
        name: "Renasant Locations",
        description:
          "Created using Adobe XD, Illustrator, Photoshop, After Effects, Google Maps API, and WordPress. Renasant Locations is the branch finder tool for Renasant Bank. With over 250 different locations including ATMs, ITMS, general branches, mortgage departments, and insurance departments, I provided a custom SEO based solution to help generate top level content in all of the search engines.",
        url: "https://www.renasantbank.com/locations",
        external: true
      },
      // {
      //   name: "Marketing Proposal",
      //   description:
      //     "Created using Adobe Illustrator, the Marketing Proposal page is a stopgap for parties interested in contacting Renasant Bank for any connection other than personal or business banking.",
      //   url: "https://www.renasantnation.com/proposal",
      //   external: true
      // },
      {
        name: "Rise With Renasant",
        description:
          "Created using Adobe XD, Illustrator, Photoshop, After Effects, and WordPress. Rise with Renasant is a women’s empowerment campaign that has spotlight stories about different women in the community written on completely custom blog posts.",
        url: "https://www.renasantnation.com/rise",
        external: true
      },
      
      // {
      //   name: "The Best Bank in the South",
      //   description:
      //     "A landing page created for Renasant Bank, the best bank in the south for ads running on Facebook, Twitter, LinkedIn, and across the web.",
      //   url: "https://www.renasantbank.com/best-bank-in-the-south",
      //   external: true
      // }
      ]

    return (
        <section id="projects">
        <div className="container">
          <h2>Projects</h2>
          {projects.map((proj: TProject, index: number) => (
            <div className="project-container" key={index}>
              <h3>{proj.name}</h3>
              {Object(proj).hasOwnProperty("labels") || Object(proj).hasOwnProperty("languages") || Object(proj).hasOwnProperty("frameworks") ? (
                  <FlexReset>
                    <p style={{flex: "50%"}}>{proj.description}</p>
                    <div style={{flex: "50%"}}>
                      <ProjectMeta proj={proj} />
                    </div>
                  </FlexReset>
              ):(
                  <>
                    <p>{proj.description}</p>
                    <ProjectMeta proj={proj} />
                  </>
              )}
              {proj.external && (
                  <Button
                      external={proj.external}
                      url={proj.url}
                      text={proj.name}
                  />
              )}
            </div>
          ))}
        </div>
      </section>
    )
}

export default Project