import { useState, useEffect } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import EmailContact from "../parts/Email"

type ContactProps = {
    topBorder: boolean;
    subtitle: string;
    description: string;
}



const Contact = ({topBorder, subtitle, description}: ContactProps) => {
    const [allowEmailAddress, setAllowEmailAddress] = useState(localStorage.getItem("_grecaptcha") === null)
    
    const handleAllowEmailAddress = () => setAllowEmailAddress(prevState => !prevState)

    console.log("val: ", allowEmailAddress)

    useEffect(() => {
        window.addEventListener("storage", handleAllowEmailAddress)

        return () => window.addEventListener("storage", handleAllowEmailAddress)

    }, [])

    const onChange = (value: any) => {
        console.log("captcha value: ", value)
        handleAllowEmailAddress()
    }

    return (
        <section id="contact">
            <div className={topBorder ? "container" : "alt-container"}>
                <h2>Contact Me</h2>
                <div className="contactBlock">
                    <h3>{subtitle}</h3>
                    <p>{description}</p>
                    {allowEmailAddress ? (
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_CAPTCHA_KEY || ""}
                            onChange={onChange}
                        />
                    ):(
                        <EmailContact email="business@akrausemedia.com"/>
                    )}

                </div>
            </div>
        </section>
    )
}

export default Contact
