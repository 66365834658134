type AppNavProps = {
    logo: string;
}


const AppNavigation = ({logo}: AppNavProps) => {

    return (
        <header>
            <div className={"top-nav"}>
                <img src={logo} alt="Andrew Krause Logo"/>
                <nav>
                    <a href={"#contact"} className="inverse-btn">Contact</a>
                </nav>
            </div>
        </header>
    )
}

export default AppNavigation
