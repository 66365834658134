import Button from '../parts/Button'


type ATFProps = {
    title?: string;
    description?: string;
}

const AboveTheFold = ({title, description}: ATFProps) => { 
    return (
        <section id="atf">
            <div className="container">
                <h1>{title}</h1>
                <p>{description}</p>
                <div className="button-container">
                    <Button url={"#contact"} icon={"brain"} text={"Get In Touch"} btnType={"important"} />
                </div>
            </div>
        </section>

    )
}

AboveTheFold.defaultProps = { 
    title: "Hello, I'm Andrew.",
    description: "I'm a software engineer interested in UI/UX design, creating components in React, and scalable design systems. I do volunteer work with Team Rubicon and help others learn how to design and program on YouTube.",
}

export default AboveTheFold
