
const Copyright = () => { 
    let currentYear = new Date();

    return (
        <p className="copyright">
        {"Copyright 2016 - " + currentYear.getFullYear()}
      </p>  
    )
}

export default Copyright