// @ts-ignore
import resume from "../assets/files/2021-05-24-Andrew_Krause_Resume_2021.pdf"

type EmailProps = {
    email: string;
}


const EmailContact = ({email}: EmailProps) => (
    <>
        <p>
            Email me: {" "}
            <a href={`mailto: ${email}`}>{email}</a><br /><br />
            <a href={resume} target={"_blank"} rel={"noreferrer noopener"}>View My Resume</a>
        </p>
    </>
)


export default EmailContact