import styled from 'styled-components'

type TFlexDisplay = {
    justify?: string
    align?: boolean
}

export const FlexDisplay = styled.div<TFlexDisplay>`
  display: flex !important;
  align-items: ${props => props.align ? "start" : "center"} !important;
  justify-content: ${props => props.justify ? props.justify : undefined} !important;
`

export const FlexReset = styled.div`
  display: block;
`