
type IconProps = { 
    icon?: string
    brandColor?: string
    brandIcon?: boolean
    text?: string
}

type IconBoxProps = IconProps & { text: string }

const Icon = ({icon, brandColor, brandIcon}: IconProps) => { 

    return (
        <div>
      {icon ? (
        <i
          style={{ color: `${brandColor}` }}
          className={(brandIcon ? "fab fa-" : "fal fa-") + `${icon}`}
        ></i>
      ) : null}
        </div>
    )
}

Icon.defaultProps = { 
    brandIcon: false,
}

export default Icon


export const IconBox = ({ brandIcon, icon, brandColor, text }: IconBoxProps) => {
  return (
    <div className="iconbox">
      <div className="iconbox-container">
        <Icon brandIcon={brandIcon} icon={icon} brandColor={brandColor} />
        <h3>{text}</h3>
      </div>
    </div>
  );
};


export const IconLink = ({url, text, icon, brandColor, brandIcon}: IconBoxProps & { url: string}) => { 

  return (
    <a href={url} className="iconlink" target={"_blank"} rel={"noreferrer noopener"}>
    {icon ? (
      <div>
        <i
          style={{ color: `${brandColor}` }}
          className={
            (brandIcon ? "fab fa-" : "fal fa-") +
            `${icon}`
          }
        ></i>
        <span style={{ display: "none" }}>{icon}</span>
      </div>
    ) : null}
    {text}
  </a>
  )
}