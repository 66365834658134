import React, { useEffect, useState, createContext, ReactNode } from 'react'
import { createGlobalStyle } from "styled-components";

type ThemeContextProps = any | null | string | undefined


export type Props = {
    children: ReactNode;
};


export const ThemeData = createContext<ThemeContextProps>(null)


const ThemeProvider = ({children}: Props) => {
    const [theme, setTheme] = useState(localStorage.getItem("prefersTheme"))

//     const GlobalStyle = createGlobalStyle`
//         a { text-decoration: none; color: inherit; &:hover { color: inherit; }}
//         body { background-color: ${theme === "light" ? "#ffffff" : "#232c46"} !important; overflow-x: hidden; }
//         header { background-color: ${theme === "light" ? "#ffffff" : "#232c46"} !important; }
//         h1, h2, h3, h4, h5, h6, p, a, div { color: ${theme === "light" ? "#232c46" : "#f2f4f8"} !important; overflow-x: hidden; }
//         .container, .footer-container {
//           border-top: 1px solid ${theme === "light" ? "#d2d8ea" : "#2d395a"} !important;
//         }
//         .button { 
//           background-color: ${theme === "light" ? "#ffffff" : "#232C46"} !important;
//           color: ${theme === "light" ? "#232C46" : "#F2F4F8"} !important;
//           border: ${theme === "dark" ? "1px solid #F2F4F8" : ""} !important;
          
//           &:hover {
//             background-color: ${theme === "light" ? "#232C46" : "#F2F4F8"} !important;
//             color: ${theme === "light" ? "#ffffff" : "#232C46"} !important;
//           }
//         }
//         .button-main, .inverse-btn {
//           background-color: ${theme === "light" ? "#232C46" : "#f2f4f8"} !important;
//           color: ${theme === "light" ? "#f2f4f8" : "#232C46"} !important;
//         }
//         .akm-badge {
//           li {
//             background-color: ${theme === "light" ? "#f2f4f8" : "#2d395a"} !important;
//             color: ${theme === "light" ? "#2d395a" : "#f2f4f8"} !important;
//           }
//         }
//     `


    useEffect(() => {
        if(theme === null) {
            localStorage.setItem("prefersTheme", "light")
        }
    }, [theme])

    const toggleThemeState = () => {
        if(theme === "light") {
            localStorage.setItem("prefersTheme", "dark")
            setTheme("dark")
        } else {
            localStorage.setItem("prefersTheme", "light")
            setTheme("light")
        }

        return theme
    }

    console.log("theme: ", theme)

    const data = {
        theme,
        toggleThemeState
    }

    return (
        <ThemeData.Provider value={data}>
            {/*<GlobalStyle />*/}

            {children}
        </ThemeData.Provider>
    )
}


export default ThemeProvider
